<style lang="scss">
.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.close-button {
  position: absolute;
  top: -12px;
  right: 6px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
</style>
<!--eslint-disable-->
<template>
  <div class="network-page">
    <section v-if="!loading">
      <div v-if="possession" class="row">
        <div class="col-12">
          <b-card class="" title="Possession Details">
            This page contains details on your possession.
          </b-card>
        </div>
        <div class="col-9">
          <b-card class="">
            <section>
              <h4 class="mb-1">Details</h4>

              <div class="row mt-2">
                <div class="col-md-6">
                  <table class="table table-striped">
                    <tbody>
                      <tr v-if="possession.owner">
                        <td class="pr-1">Owner</td>
                        <td>
                          <router-link v-if="$can('Read', 'User')" :to="`/owners/owner/${possession.owner.uid}`">
                            {{ possession.owner.identifier }}
                          </router-link>
                          <span v-else>{{ possession.owner.identifier }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Title</td>
                        <td>{{ possession.title }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Description</td>
                        <td>{{ possession.description }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Colour</td>
                        <td>{{ possession.colour }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Location</td>
                        <td>{{ possession.location.name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Cost</td>
                        <td>£{{ possession.cost_of_item }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped">
                    <tbody>
                      <tr>
                        <td class="pr-1">Is Risk</td>
                        <td>{{ possession.is_risk ? 'Yes' : 'No' }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Is Prohibited</td>
                        <td>{{ possession.is_prohibited ? 'Yes' : 'No' }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Category</td>
                        <td>{{ possession.category.name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Sub Category</td>
                        <td>{{ possession.subcategory.name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Origin</td>
                        <td>{{ possession.origin }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-4"></div>
              </div>
            </section>
          </b-card>

          <b-card class="mt-2">
            <section>
              <h4 class="mb-1">Images</h4>

              <div class="row mt-2" v-if="possession.images.length > 0">
                <div class="col-md-12">
                  <div class="row">
                    <div
                      v-for="image in possession.images"
                      :key="image.id"
                      class="col-md-4 mt-4"
                    >
                      <b-img thumbnail fluid :src="getImgUrl(image.file_location)" class="image-container"/>
                      <button class="close-button" @click="showDeleteImageModal(image.id)">x</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-4"></div>
              </div>

              <div v-else>
                <p>No images uploaded</p>
              </div>
            </section>
          </b-card>

        </div>

        <div class="col-3">
          <b-card class="">
            <p class="font-weight-bolder">Actions</p>
            <b-btn class="full-width mb-1" variant="primary" @click="showModal('modal-update')">
              Update Possession
            </b-btn>
            <b-btn class="full-width mb-1" variant="secondary" @click="showModal('modal-upload-image')">
              Upload Image
            </b-btn>
            <b-btn v-if="$can('Delete', 'Possessions')" class="full-width mb-1" variant="danger"
                   @click="showDeleteModal">
              Delete Possession
            </b-btn>
          </b-card>
        </div>
      </div>
      <div v-else>Possession not found</div>


      <b-modal id="modal-update" hide-footer title="Update Possession">
        <update-possession :target-possession="this.possession" @close="closeModals"></update-possession>
      </b-modal>

      <b-modal id="modal-upload-image" title="Upload possession image" hide-footer>
        <possession-image-upload-modal
          @upload="uploadImage"
          @close="$bvModal.hide('modal-upload-image')"
        ></possession-image-upload-modal>
      </b-modal>

    </section>

    <!-- Loading -->
    <section v-else>
      <b-spinner label="Loading Possession" variant="primary"></b-spinner>
    </section>
  </div>
</template>
<!--eslint-enable-->

<script>
import PossessionsService from "@/services/PossessionsService";
import possessionsService from "@/services/PossessionsService";
import UpdatePossession from "@/views/owners/sections/UpdatePossession.vue";
import PossessionImageUploadModal from "@/views/possessions/modals/PossessionImageUploadModal.vue";
import helperService from "@/services/HelperService";
import CountryService from "@/services/CountryService";

export default {
  components: {PossessionImageUploadModal, UpdatePossession},
  data() {
    return {
      loading: true,
      possession: null,
    }
  },
  mounted() {
    this.getPossession()
  },
  methods: {
    CountryService() {
      return CountryService
    },
    async getPossession() {
      this.loading = true
      try {
        const res = await PossessionsService.get(this.$route.params.id)
        this.possession = res.data
        try {
          this.possession.meta = JSON.parse(this.possession.meta)
        } catch (_) {
        }
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get possession, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
      this.loading = false;
    },
    async deletePossession() {
      try {
        await PossessionsService.delete(this.$route.params.id)
        this.$router.push({name: 'possessions'})
        this.$toast.success('Possession was successfully deleted.', {
          toastClassName: ['toast-std', 'success-toast'],
        })
      } catch (err) {
        const res = err.response
        let errorText = 'Could not delete possession, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    async uploadImage(image) {
      try {
        const res = await PossessionsService.uploadImage(this.possession.id, image)
        helperService.showNotfySuccess(this.$toast, 'Image uploaded')
        this.possession.images = res.images;
        this.$bvModal.hide('modal-upload-image')
      } catch(err) {
        this.$bvModal.hide('modal-upload-image')
        const res = err.response
        let errorText = 'Could not upload possession image, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }
        helperService.showNotfyErr(this.$toast, err, errorText)
      }
    },
    showDeleteModal() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this possession? This action cannot be undone.', {
        title: 'Delete Possession',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value) {
          this.deletePossession()
        }
      })
    },
    showDeleteImageModal(id) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this image? This action cannot be undone.', {
        title: 'Delete Image',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value) {
          this.deleteImage(id)
        }
      })
    },
    async deleteImage(id) {
      try {
        await PossessionsService.deleteImage(this.possession.id, id)
        this.possession.images = this.possession.images.filter(img => img.id !== id)
      } catch(err) {
        const res = err.response
        let errorText = 'Could not delete possession image, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }
        helperService.showNotfyErr(this.$toast, err, errorText)
      }
    },
    showModal(modalName) {
      this.$bvModal.show(modalName);
    },
    closeModals() {
      this.$bvModal.hide('modal-update');
      this.getPossession();
    },
    getImgUrl(fileLocation) {
      return `${CountryService.getApiUrl()}/files/${fileLocation}`
    }
  },
}
</script>
