<template>
  <div class="upload-image-modal">
    <section class="mb-2">
      <label>Upload image</label>
      <b-form-file ref="upload-image"
                   v-model="image"
                   size="lg"
                   required
                   accept="image/jpeg, image/png"
                   placeholder="Choose an image or drop it here..."
                   drop-placeholder="Drop file here..."
                   @change="getImageUrl"
      />
      <div v-if="invalidImgType" class="alert alert-danger p-1 mt-1" role="alert">
        Invalid image, please try again
      </div>
    </section>
    <section v-if="preview_url" class="mb-2 d-flex justify-content-center">
      <img class="img-thumbnail w-50" style="padding: 0; border: none" :src="preview_url" alt="image_preview">
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="clearFiles(); $emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !image || invalidImgType}"
                variant="primary" @click="uploadImage"
      >Upload image</b-button>
    </section>

  </div>
</template>

<script>
export default {
  name: 'PossessionImageUploadModal',
  components: {},
  data() {
    return {
      image: null,
      preview_url: '',
      invalidImgType: false,
    };
  },
  methods: {
    async uploadImage() {
      if (this.image && !this.invalidImgType && !this.invalidImgRes) {
        this.$emit('upload', this.image);
      }
    },
    clearFiles() {
      this.$refs['upload-image'].reset()
      this.preview_url = ''
    },
    getImageUrl(e) {
      const file = e.target.files[0];
      this.preview_url = URL.createObjectURL(file);
    },
  },
};
</script>
<style scoped>
</style>
